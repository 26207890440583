/*
structure seeks to follow Inverted Triangle CSS: https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/

1. Settings
- If you’re using a preprocessor like SCSS, 
this is your starting point. In this layer you define your variables.

2. Tools
- This layer can be used for your tooling. Think about mixins and functions that need to be globally available. 
If they don’t need to be, just place them in the layer where they’re needed.

3. Generic
- In this layer, we house all the very high-level, far reaching styles. This layer is often the same across all your projects as it contains things like Normalize.css, CSS resets, and for example box-sizing rules.

4. Elements
- In this layer we put styles for bare, unclassed HTML elements. You could, for example, think about underlines for anchors on hover or font-sizes for the different headings.

5. Objects
- In the object layer we style the first elements that have classes. Think about your containers, wrappers or rows. You can also define your grid here.

6. Components
- The component layer is the place where most of the styling magic happens as you will be styling your UI elements here. In component based frameworks like Angular, Vue or React this is the layer where you import your styling for each component if you don’t include them directly in your component.

7. Trumps
- The trumps layer is the dirty layer. Even after structuring your styling according to the ITCSS principles it can happen that you have to use !important to override some third-party styling, for example. Do that in this layer as this is the most specific, local and explicit layer.
*/
@import 'scss/palette.scss';
@import 'scss/elements.scss';
