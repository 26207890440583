// Palette
$color-blue: #00bcd4;
$color-blue-light: #7fdde9;
$color-blue-dark: #234763;

$color-ink: #232f3e;
$color-ink-light: #31465f;
$color-ink-dark: #152939;

$color-material: #7d878c;
$color-material-light: #f5f5f5;
$color-material-dark: #37474f;

$color-red: #d24a3c;
$color-silver: #e1e4ea;
$color-white: #ffffff;
$color-grey: #666666;

$color-green: #8bc34a;
$color-orange: #ef9c0f;
